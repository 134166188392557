import { FinalIssuedAction, DataExchangeType, ActionType, UserCookieModel, ClientSampling, DebuggerEventType, SeverityLogLevel } from "../api/client/clientApi";
import { UpdatedStateDebuggerData } from "../SmartSignalsProfileDebugger/resources/models/UpdatedStateDebuggerData";
import { ApiRequestResponseData, RequestType } from "../SmartSignalsProfileDebugger/types/TrackingRequestResponseData";
import { TrackingClientEventName } from "./ITrackingClient.public";

export const ENGINE_VERSION = '202409161131';

export interface ISamplinData {
    /** selected */
    s: boolean;

    /** sampling ration version */
    v: number;

    /** Time stamp */
    ts: number;
}

export interface IProfileDebuggerData {
    /** debugger state (0 - hidden, 1 - shown, 2 - minimized */
    s: DebuggerState;

    /** Debugger window height */
    ht: number;
}

export enum DebuggerState {
    OFF = 0,
    ON = 1,
    MIN = 2
}

export interface IUserInfo {
    userId: string | undefined;
    scriptId: string;
    nativeEventTracking: boolean;
    xdmRecognition: boolean;
    adobeIdCookie?: string;
    closeOverlayOnUrlChange: boolean;
    sampledUser?: ClientSampling;
    crossDomainCookie: boolean;
    firstPartyTracking: boolean;
    url: string
}

export interface IEngine {
    conf: IConfiguration;
    getActionHandler: (actionType: ActionType) => any;
    getHandoverHandler: (dataExchangeType: DataExchangeType) => any;
    unissuedAction: (action: FinalIssuedAction) => void;
    trigger(eventName: TrackingClientEventName, data: any): void;

    getCurrentUrl(): string;
    getClientSampling(): ClientSampling | undefined;
    getUserId(getTempUids?: boolean): Promise<string | undefined>;
    getCrossDomainCookie(): Promise<UserCookieModel | null>;
    setProfileDebuggerState(state: DebuggerState, height?: number): void;
    debuggerEvent(et: DebuggerEventType): void;
    getProfileDebuggerConfig(): IProfileDebuggerData;
    setConfiguration<T>(key: string, config: T): void;
    getConfiguration<T>(key: string): T | undefined;
    debuggerReady(): void;
    trackApiCall<TResponseData>(requestType: RequestType, data: any, api: Promise<TResponseData>): Promise<TResponseData>;
    env: string;
    sendHandlingState(data: UpdatedStateDebuggerData): void;
}
